import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "50",
  viewBox: "0 0 50 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M7.12158 2.5V47.5H42.8789V13.679L32.1388 2.5H7.12158Z",
      fill: "#E3673E"
    }, null, -1),
    _createElementVNode("path", {
      d: "M38.4898 14.9441L29.6602 6.1145V14.9441H38.4898Z",
      fill: "white"
    }, null, -1),
    _createElementVNode("path", {
      fill: "white",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M35.1078 26.278H15.125V23.6963H35.1078V26.278Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "white",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M35.1078 31.5705H15.125V28.9888H35.1078V31.5705Z"
    }, null, -1)
  ])))
}
export default { render: render }